import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/cs';

import { useAuth0 } from "@auth0/auth0-react";

import { ThemeProvider, createTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SummarizeIcon from '@mui/icons-material/Summarize';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import CircularProgress from '@mui/material/CircularProgress';

import { Form } from "type-safe-form";

import { api, setHeaders } from "./services/api";
import { Count, Place, Record, RecordType, RecordSell, RecordDonate, RecordMoveFrom, RecordMoveTo, RecordPrint, User, Permission } from './types';

moment.locale('cs');

const darkTheme = createTheme({
  palette: { mode: "dark" },
  typography: { fontSize: 14, h1: { fontSize: 32 } },
});

const places: Place[] = ["Koloděje", "AirPark", "Zapova", "Tusarova", "Markéta", "E-shop", "Action"];

export default function App() {
  const { user: auth0User, logout, loginWithRedirect, getAccessTokenSilently } = useAuth0();

  const [user, setUser] = useState<null | User>(null);
  useEffect(() => {
    if (auth0User && user === null) {
      getAccessTokenSilently().then(accessToken => {
        setUser({
          id: auth0User.sub || "",
          name: auth0User.name || "",
          picture: auth0User.picture || "",
          accessToken,
        });
        setHeaders({ Authorization: `Bearer ${accessToken}` });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth0User, user]);

  const [permission, setPermission] = useState<null | Permission>(null);
  useEffect(() => {
    if (permission === null && user !== null) {
      api.getPermissions({}).then(([permission]) => {
        if (permission?.admin === true) {
          setPermission({ ...permission, places });
        } else {
          setPermission(permission);
        }
      });
    }
  }, [permission, user]);

  const [records, setRecords] = useState<null | Record[]>(null);
  const [error, setError] = useState<null | string>(null);
  useEffect(() => {
    if (records === null && permission !== null && user !== null) {
      api.getRecords({}).then(([records, error]) => {
        setRecords(records);
        setError(error);
      });
    }
  }, [records, permission, user]);

  const recordsArray = Array.isArray(records) ? records : [];

  const printedCount = getPrintedCount(recordsArray);
  const storedCount = getStoredCount(recordsArray);
  const soldCount = getSoldCount(recordsArray);
  const donateCount = getDonateCount(recordsArray);
  const priceCount = getPriceCount(recordsArray);

  return <ThemeProvider theme={darkTheme}>
    <Box
      sx={{
        display: 'flex',
        flexDirection: "column",
        width: '100%',
        minHeight: "100vh",
        bgcolor: 'background.default',
        color: 'text.primary',
      }}
    >
      <AppBar position="fixed">
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            component="div"
          >
            Mathesso skladový systém
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          {user && <>
            <Typography>{user.name}</Typography>
            <Avatar alt={user.name} src={user.picture} sx={{ ml: 2, mr: 2 }} />
            <Button onClick={() => logout({ returnTo: window.location.origin })}>Odhlásit se</Button>
          </>}
          {!user && <Button onClick={() => loginWithRedirect()}>Přihlásit se</Button>}
        </Toolbar>
      </AppBar>

      <Box sx={{ height: "62px" }} />

      {user === null && <Typography sx={{ p: 16, textAlign: "center" }}>Nejprve je nutné se přihlásit.</Typography>}
      {error !== null && <Typography sx={{ p: 16, textAlign: "center" }}>
        {error === "No permission" ? "Váš účet nevlastní žádná práva" : error}
      </Typography>}

      {records === null && user !== null && error === null && <Box sx={{ display: "flex", justifyContent: "center", pt: 16 }}>
        <CircularProgress />
      </Box>}

      {user && permission && Array.isArray(records) && <Box sx={{ p: 2 }}>
        <Box sx={{ display: "flex" }}>
          <Card title="Celkem vytištěno" count={printedCount} />
          <Card title="Ve skladech" count={storedCount} />
          <Card title="Celkově prodáno" count={soldCount} />
          <Card title="Celkově darováno" count={donateCount} />
          {permission?.admin === true && <Card title="Celkově obdrženo" count={priceCount} end="Kč" />}
        </Box>

        {places.filter(place => permission.places?.includes(place)).map(place => <PlaceElement
          key={place}
          user={user}
          permission={permission}
          place={place}
          records={records.filter(r => r.place === place)}
          onReload={() => setRecords(null)}
        />)}
      </Box>}

    </Box>
  </ThemeProvider>;
}

function Card({ title, count, end = "" }: { title: string, count: Count, end?: string }) {
  return <Paper sx={{
    m: 2,
    p: 2,
    flexGrow: 1
  }}>
    <Typography variant='h6'>{title}</Typography>

    <Box>
      <Typography component="span" variant='h3'>{count.standard.toLocaleString()}{end ? ` ${end}` : ""}</Typography>
      <Typography component="sub" sx={{ pl: 1 }}>Standard</Typography>
    </Box>
    <Box>
      <Typography component="span" variant='h3'>{count.luxury.toLocaleString()}{end ? ` ${end}` : ""}</Typography>
      <Typography component="sub" sx={{ pl: 1 }}>Luxusní</Typography>
    </Box>
    <Box>
      <Typography component="span" variant='h3'>{(count.travel || 0).toLocaleString()}{end ? ` ${end}` : ""}</Typography>
      <Typography component="sub" sx={{ pl: 1 }}>Travel</Typography>
    </Box>
  </Paper>
}

function PlaceElement({ user, permission, place, records, onReload }: { user: User, permission: Permission, place: Place, records: Record[], onReload: () => void }) {

  const [dialog, setDialog] = useState<null | RecordType>(null);

  const storedCount = getStoredCount(records || []);
  const soldCount = getSoldCount(records || []);
  const donateCount = getDonateCount(records || []);
  const priceCount = getPriceCount(records || []);

  return <Box sx={{ mt: 8 }}>
    <Typography variant='h4' sx={{ pl: 2 }}>
      {place === "Action" ? "Prodej na akci" : place}
    </Typography>

    <Box sx={{ display: "flex" }}>
      <Card title="Ve skladu" count={storedCount} />
      <Card title="Prodáno" count={soldCount} />
      <Card title="Darováno" count={donateCount} />
      <Card title="Obdrženo" count={priceCount} end="Kč" />
      <Stack spacing={2} justifyContent="center">
        <Button variant="contained" onClick={() => setDialog("SELL")}>Prodat</Button>
        <Button variant="contained" onClick={() => setDialog("DONATE")}>Darovat</Button>
        {permission.admin === true && <Button variant="contained" onClick={() => setDialog("MOVE_FROM")}>Přesunout</Button>}
        {permission.admin === true && <Button variant="contained" onClick={() => setDialog("PRINT")}>Nové</Button>}
      </Stack>
    </Box>

    <Box sx={{ ml: 2 }}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>List transakcí</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {records.length === 0 && <Typography>Nejsou zde zatím žádné transakce.</Typography>}
          {records.length > 0 && <Table>
            <TableHead>
              <TableRow>
                <TableCell>Typ</TableCell>
                <TableCell>Datum</TableCell>
                <TableCell>Uživatel</TableCell>
                <TableCell>Počet</TableCell>
                <TableCell>Komentář</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {records.sort((a, b) => a.date.localeCompare(b.date)).map((r) => <TableRow key={r.id}>
                <TableCell>
                  {r.type === "PRINT" && "Nové"}
                  {r.type === "SELL" && "Prodáno"}
                  {r.type === "DONATE" && "Darováno"}
                  {r.type === "MOVE_FROM" && "Přesunuto pryč"}
                  {r.type === "MOVE_TO" && "Přesunuto sem"}
                </TableCell>
                <TableCell>{moment(r.date).format("LLL")}</TableCell>
                <TableCell>{r.userName}</TableCell>
                <TableCell><CountTableCell {...r.count} end="" /></TableCell>
                <TableCell>{r.comment}</TableCell>
                <TableCell>
                  {r.type === "SELL" && <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <CountTableCell {...r.price} end="Kč" />
                    {r.invoiceNumber === null ? "Bez faktury" : <Box>
                      <IconButton color="primary" onClick={() => alert("Připravuje se")}>
                        <SummarizeIcon />
                      </IconButton>
                      <IconButton color="primary" onClick={() => alert("Připravuje se")}>
                        <FactCheckIcon />
                      </IconButton>
                    </Box>}
                  </Box>}
                  {r.type === "DONATE" && <Box>{`Komu: ${r.who}`}</Box>}
                  {r.type === "MOVE_FROM" && <Box>{`Kam: ${r.placeTo}`}</Box>}
                  {r.type === "MOVE_TO" && <Box>{`Z: ${r.placeFrom}`}</Box>}
                </TableCell>
              </TableRow>)}
            </TableBody>
          </Table>}
        </AccordionDetails>
      </Accordion>
    </Box>

    <Dialog fullWidth maxWidth="sm" onClose={() => setDialog(null)} open={dialog !== null} >
      {dialog === "SELL" && <>
        <DialogTitle>Prodej Mathess</DialogTitle>
        <Form
          initialValues={{
            type: "SELL",
            date: moment().format(),
            place,
            count: { standard: 0, luxury: 1, travel: 0 },
            userId: user.id,
            userName: user.name,
            comment: "",
            isPaid: false,
            invoiceNumber: null,
            price: { standard: 0, luxury: 1221, travel: 0 },
          } as Omit<RecordSell, "id">}
          onSubmit={async record => {

            await api.addRecord(record);

            onReload();
            return "Mathessa prodána";
          }}
        >
          {({ Input, values, message, isChanged, onChange, onSubmit }) => (
            <>
              <DialogContent>
                <Input.Count>
                  {({ Input }) => <>
                    <Input.Standard label='Počet Standardních mathess' onChange={standard => onChange({
                      ...values,
                      count: { ...values.count, standard },
                      price: { ...values.price, standard: standard * 777 }
                    })} />
                    <Input.Luxury label='Počet Luxusních mathess' onChange={luxury => onChange({
                      ...values,
                      count: { ...values.count, luxury },
                      price: { ...values.price, luxury: luxury * 1221 }
                    })} />
                    <Input.Travel label='Počet Travel mathess' onChange={travel => onChange({
                      ...values,
                      count: { ...values.count, travel },
                      price: { ...values.price, travel: travel * 271 }
                    })} />
                  </>}
                </Input.Count>
                {/* <Input.InvoiceNumber notNullValue={82105001} label="Vygenerovat fakturu s číslem" /> */}
                <Input.Price>
                  {({ Input }) => <>
                    <Input.Standard label='Cena Standardních mathess - Kč' />
                    <Input.Luxury label='Cena Luxusních mathess - Kč' />
                    <Input.Travel label='Cena Luxusních mathess - Kč' />
                  </>}
                </Input.Price>
                <Input.Comment label='Poznámka' type="textarea" />

                {message && <Alert severity="success">{message}</Alert>}
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setDialog(null)}>Zrušit</Button>
                <Button onClick={onSubmit} variant="contained">Prodat</Button>
              </DialogActions>
            </>)}
        </Form>
      </>}

      {dialog === "DONATE" && <>
        <DialogTitle>Darování Mathess</DialogTitle>
        <Form
          initialValues={{
            type: "DONATE",
            date: moment().format(),
            place,
            count: { standard: 0, luxury: 1, travel: 0 },
            userId: user.id,
            userName: user.name,
            comment: "",
            who: "",
          } as Omit<RecordDonate, "id">}
          onSubmit={async record => {

            await api.addRecord(record);

            onReload();
            return "Mathessa darována";
          }}
        >
          {({ Input, values, message, isChanged, onSubmit }) => (
            <>
              <DialogContent>
                <Input.Count>
                  {({ Input }) => <>
                    <Input.Standard label='Počet Standardních mathess' />
                    <Input.Luxury label='Počet Luxusních mathess' />
                    <Input.Travel label='Počet Travel mathess' />
                  </>}
                </Input.Count>
                <Input.Who required label='Komu' />
                <Input.Comment label='Poznámka' type="textarea" />

                {message && <Alert severity="success">{message}</Alert>}
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setDialog(null)}>Zrušit</Button>
                <Button onClick={onSubmit} variant="contained">Darovat</Button>
              </DialogActions>
            </>)}
        </Form>
      </>}

      {dialog === "PRINT" && <>
        <DialogTitle>Přišli nově vytištěná Mathessa</DialogTitle>
        <Form
          initialValues={{
            type: "PRINT",
            date: moment().format(),
            place,
            count: { standard: 0, luxury: 1, travel: 0 },
            userId: user.id,
            userName: user.name,
            comment: "",
          } as Omit<RecordPrint, "id">}
          onSubmit={async record => {

            await api.addRecord(record);

            onReload();
            return "Mathessa uložena do skladu";
          }}
        >
          {({ Input, values, message, isChanged, onSubmit }) => (
            <>
              <DialogContent>
                <Input.Count>
                  {({ Input }) => <>
                    <Input.Standard label='Počet Standardních mathess' />
                    <Input.Luxury label='Počet Luxusních mathess' />
                    <Input.Travel label='Počet Travel mathess' />
                  </>}
                </Input.Count>
                <Input.Comment label='Poznámka' type="textarea" />

                {message && <Alert severity="success">{message}</Alert>}
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setDialog(null)}>Zrušit</Button>
                <Button onClick={onSubmit} variant="contained">Uložit do skladu</Button>
              </DialogActions>
            </>)}
        </Form>
      </>}

      {dialog === "MOVE_FROM" && <>
        <DialogTitle>Přesun Mathess do jiného skladu</DialogTitle>
        <Form
          initialValues={{
            type: "MOVE_FROM",
            date: moment().format(),
            place,
            placeTo: "Koloděje",
            count: { standard: 0, luxury: 1, travel: 0 },
            userId: user.id,
            userName: user.name,
            comment: "",
          } as Omit<RecordMoveFrom, "id">}
          onSubmit={async ({ type, place, placeTo, ...record }) => {

            const recordMoveFrom: Omit<RecordMoveFrom, "id"> = { type, place, placeTo, ...record };
            const recordMoveTo: Omit<RecordMoveTo, "id"> = { type: "MOVE_TO", place: placeTo, placeFrom: place, ...record };

            await api.addRecord(recordMoveFrom);
            await api.addRecord(recordMoveTo);

            onReload();
            return "Mathessa přesunuta";
          }}
        >
          {({ Input, values, message, isChanged, onSubmit }) => (
            <>
              <DialogContent>
                <Input.Count>
                  {({ Input }) => <>
                    <Input.Standard label='Počet Standardních mathess' />
                    <Input.Luxury label='Počet Luxusních mathess' />
                    <Input.Travel label='Počet Travel mathess' />
                  </>}
                </Input.Count>
                {/* @ts-ignore */}
                <Input.PlaceTo.Select options={places.map(place => ({ value: place, text: place }))} />
                <Input.Comment label='Poznámka' type="textarea" />

                {message && <Alert severity="success">{message}</Alert>}
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setDialog(null)}>Zrušit</Button>
                <Button onClick={onSubmit} variant="contained">Přesunout</Button>
              </DialogActions>
            </>)}
        </Form>
      </>}

    </Dialog>
  </Box>
}

function CountTableCell({ standard, luxury, travel, end }: Count & { end: string }) {
  return <Box sx={{ display: "flex", alignItems: "flex-end" }}>
    <Typography>{standard.toLocaleString()}</Typography>
    <Typography sx={{}}>{end}</Typography>
    <Typography sx={{ fontSize: 12, pl: 0.5, pr: 2 }}>std</Typography>

    <Typography>{luxury.toLocaleString()}</Typography>
    <Typography sx={{}}>{end}</Typography>
    <Typography sx={{ fontSize: 12, pl: 0.5 }} component="sub">lux</Typography>

    <Typography>{travel.toLocaleString()}</Typography>
    <Typography sx={{}}>{end}</Typography>
    <Typography sx={{ fontSize: 12, pl: 0.5 }} component="sub">lux</Typography>
  </Box>;
}

// ------------ calculation services --------------
function plusCount(c1: Count, c2: Count): Count {
  return { standard: c1.standard + c2.standard, luxury: c1.luxury + c2.luxury, travel: c1.travel + c2.travel };
}
function minusCount(c1: Count, c2: Count): Count {
  return { standard: c1.standard - c2.standard, luxury: c1.luxury - c2.luxury, travel: c1.travel - c2.travel };
}

function getPrintedCount(records: Record[]): Count {
  // eslint-disable-next-line array-callback-return
  return records.reduce<Count>((count, record) => {
    switch (record.type) {
      case "PRINT": return plusCount(count, record.count);
      default: return count;
    }
  }, { standard: 0, luxury: 0, travel: 0 });
}
function getStoredCount(records: Record[]): Count {
  // eslint-disable-next-line array-callback-return
  return records.reduce<Count>((count, record) => {
    switch (record.type) {
      case "PRINT": return plusCount(count, record.count);
      case "SELL": return minusCount(count, record.count);
      case "DONATE": return minusCount(count, record.count);
      case "MOVE_FROM": return minusCount(count, record.count);
      case "MOVE_TO": return plusCount(count, record.count);
    }
  }, { standard: 0, luxury: 0, travel: 0 });
}
function getSoldCount(records: Record[]): Count {
  // eslint-disable-next-line array-callback-return
  return records.reduce<Count>((count, record) => {
    switch (record.type) {
      case "SELL": return plusCount(count, record.count);
      default: return count;
    }
  }, { standard: 0, luxury: 0, travel: 0 });
}
function getDonateCount(records: Record[]): Count {
  // eslint-disable-next-line array-callback-return
  return records.reduce<Count>((count, record) => {
    switch (record.type) {
      case "DONATE": return plusCount(count, record.count);
      default: return count;
    }
  }, { standard: 0, luxury: 0, travel: 0 });
}
function getPriceCount(records: Record[]): Count {
  // eslint-disable-next-line array-callback-return
  return records.reduce<Count>((count, record) => {
    if (record.type === "SELL") {
      return plusCount(count, record.price);
    }
    return count;
  }, { standard: 0, luxury: 0, travel: 0 });
}
