import React from 'react';
import { render } from 'react-dom';

import { Auth0Provider } from "@auth0/auth0-react";

import './index.css';
import App from './App';

const root = document.getElementById('root') as HTMLElement;

render(
  <React.StrictMode>
    <Auth0Provider
      domain="science-21.eu.auth0.com"
      clientId="kPRdkwSQo8IONSFRr3Vdyg0Z070CV3mn"
      redirectUri={window.location.origin}
      audience="https://science-21.eu.auth0.com/api/v2/"
      cacheLocation="localstorage"
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>,
  root
);
